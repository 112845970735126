import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PriceMenu from "../components/priceMenu"

import { Col, Row, Container } from "react-bootstrap"

import { graphql } from 'gatsby'
import Img from "gatsby-image"

const Page = (props) => (
  <Layout location={props.location}>
    <SEO
      title={props.data.page.frontmatter.title}
      description={props.data.page.frontmatter.description}
    />
    <Container>
      <div className="bg-white p-2">
        <h1 className="py-3">{props.data.page.frontmatter.title}</h1>
        <Row className="justify-content-md-center">
          <Col lg="9" className="p-1">
            <div dangerouslySetInnerHTML={{ __html: props.data.page.html }} />
          </Col>
          <Col lg="3" style={{ border: "1px solid #ddd" }} className="py-4">
            <PriceMenu
              location={props.location}
              filter={(item) => {
                return item.city === props.data.page.frontmatter.city
              }}
              lg="12"
              contacts={props.data.site.siteMetadata.contacts}
            />

            {props.data.image ? <Img
              fluid={props.data.image.childImageSharp.fluid}
              alt={props.data.page.frontmatter.description}
              title={props.data.page.frontmatter.title}
            /> : ``}

          </Col>
        </Row>
      </div>
    </Container>

  </Layout>
)

export default Page

export const pageQuery = graphql`
  query ($slug: String!) {
    site {
      siteMetadata {
        contacts {
          address
          city
        }
      }
    }
    image: file(name: {eq: $slug}) {
      childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
      }
    }
    page: markdownRemark(frontmatter: {slug: {eq: $slug}}) {
      html
      frontmatter {
        title
        city
        slug
        description
        features {
          text
        }
      }
    }
  }
`

